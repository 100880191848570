import { useEffect, useState } from 'react';
import { Box, Container, Divider, Link, Paper, PaperProps, SvgIconClassKey, SvgIconTypeMap, Typography } from '@mui/material';
// import NavBar from '../../components/NavBar';
import { Outlet, useLocation } from 'react-router-dom';
import useBreakpoints from '../../hooks/use-breakpoints';
import Navbar from '../home/Navbar';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Link as LinkRouter } from 'react-router-dom';
import { t } from '../../translations';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsIcon from '@mui/icons-material/Settings';
import Grid from '@mui/material/Unstable_Grid2';
import { useAuth } from '../auth/AuthProvider';
import Image from 'mui-image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Footer } from '../home/HomeLayout';

type CardProps = {
  title: string;
  description: string;
  linkTo: string;
  onClick?: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
};

const Card = ({ title, description, linkTo, onClick, Icon }: CardProps) => {
  const location = useLocation();
  const [variant, setVariant] = useState<PaperProps['variant']>('outlined');
  const selected = location.pathname.startsWith(linkTo);

  return (
    <Link component={LinkRouter} to={linkTo} sx={{ textDecoration: 'none' }}>
      <Paper
        variant={variant}
        elevation={3}
        sx={{
          display: 'flex',
          width: '100%',
          mb: 2,
          height: '80px',
          border: selected ? '2px solid black' : undefined,
          borderRadius: 5,
          px: 3,
          py: 1.5,
          overflow: 'hidden',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onMouseOver={() => setVariant('elevation')}
        onMouseLeave={() => setVariant('outlined')}
        onClick={onClick}
      >
        <Box sx={{ mr: 2 }}>
          <Icon fontSize="large" sx={{}} />
        </Box>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{description}</Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          <KeyboardDoubleArrowRightIcon fontSize="large" />
        </Box>
      </Paper>
    </Link>
  );
};

const TITLE_PER_LINK = {
  '/account/profile': t.Profile,
  '/account/favorites': t.Favorites,
  '/account/bookings': t.Bookings,
  '/account/settings': t.Security,
};

const AccountLayout = (props: any) => {
  const { currentUser } = useAuth();
  const { md } = useBreakpoints();
  const [showCards, setShowCards] = useState(false);
  const location = useLocation();

  const [title, setTitle] = useState('');
  useEffect(() => {
    const entry = Object.entries(TITLE_PER_LINK).find(([url, titleForUrl]) => location.pathname.startsWith(url));
    if (entry) {
      setTitle(entry[1]);
    }
  }, [location.pathname]);

  const cards = (
    <Box>
      <Paper variant="outlined" sx={{ textAlign: 'center', width: '100%', display: 'flex', borderRadius: 5, px: 3, py: 4 }}>
        {currentUser?.image && (
          <Box sx={{ display: 'inline-block', width: '36px', height: '36px', borderRadius: '50%', overflow: 'hidden', mt: 1, mr: 2 }}>
            <Image src={currentUser?.image || ''} duration={0} />
          </Box>
        )}
        <Box>
          <Typography variant="h6" textAlign="center">
            {t.Hi}, {currentUser?.fullName}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {currentUser?.email}
          </Typography>
        </Box>
      </Paper>

      <Box sx={{ my: 3, px: 1 }}>
        <Divider />
      </Box>

      <Card
        Icon={PermIdentityIcon}
        title={t.Profile}
        description={t.ProvideYourPersonalDetails}
        linkTo="/account/profile"
        onClick={() => setShowCards(false)}
      />
      <Card
        Icon={ApartmentIcon}
        title={t.Bookings}
        description={t.SeeYourReservations}
        linkTo="/account/bookings"
        onClick={() => setShowCards(false)}
      />
      <Card
        Icon={FavoriteBorderIcon}
        title={t.Favorites}
        description={t.CheckYourFavoriteProperties}
        linkTo="/account/favorites"
        onClick={() => setShowCards(false)}
      />
      <Card
        Icon={SettingsIcon}
        title={t.Security}
        description={t.CheckYouSecurityAndSettings}
        linkTo="/account/settings"
        onClick={() => setShowCards(false)}
      />
    </Box>
  );

  const content = (
    <Paper variant="outlined" sx={{ py: 4, px: 5, borderRadius: 5, maxHeight: '1000px', overflowX: 'hidden' }}>
      <Outlet />
    </Paper>
  );

  return (
    <>
      <Navbar skipHeader />
      <Container maxWidth="xl" sx={{ pt: 6, pb: 4 }}>
        {md ? (
          <Grid container spacing={4}>
            <Grid md={5} lg={4}>
              {cards}
            </Grid>
            <Grid md={7} lg={8}>
              {content}
            </Grid>
          </Grid>
        ) : (
          <Box>
            {showCards ? (
              cards
            ) : (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <ArrowBackIcon fontSize="large" color="primary" sx={{ mr: 2, cursor: 'pointer' }} onClick={() => setShowCards(true)} />
                  <Typography variant="h4">{title}</Typography>
                </Box>
                {content}
              </Box>
            )}
          </Box>
        )}
      </Container>
      <Footer skipSubscription />
    </>
  );
};

export default AccountLayout;
